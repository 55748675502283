var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", { attrs: { src: require("@/assets/img/404.png"), alt: "" } }),
    _c("h3", [_vm._v("出错啦! 无法打开页面")]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "btns" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary", size: "medium", plain: "" } },
          [_vm._v("刷新")]
        ),
        _c("el-button", { attrs: { type: "primary", size: "medium" } }, [
          _vm._v("返回首页")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("label", [_vm._v("原因：")]),
      _c("ul", [
        _c("li", [_vm._v("1、您访问的页面地址有误")]),
        _c("li", [_vm._v("2、您访问的页面不存在")]),
        _c("li", [_vm._v("3、无网络连接")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }